<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <!--订单状态步骤条-->
    <a-steps v-if="this.record.id" :current="this.record.orderStatus" size="small">
      <a-step v-for="(item, index) in this.record.operateLogList" :key="index" >
        <template slot="title">
          <div style=" font-size: 18px">{{item.statusEvent}}</div>
          <div>{{item.orderStatusName}}</div>
          <div>{{item.createTime}}</div>
        </template>
      </a-step>
    </a-steps>

    <div class="order-info">
      <table>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="订单信息"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">订单编号：</td>
                  <td class="data">
                    {{ this.record.id  }}
                  </td>
                  <td class="first">订单类型：</td>
                  <td class="data">
                    {{ this.record.orderTypeName  }}
                  </td>
                  <td class="first">订单状态：</td>
                  <td class="data">
                    {{this.record.orderStatusName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">用户名：</td>
                  <td class="data">
                    {{ this.record.nickname  }}
                  </td>
                  <td class="first">用户手机号：</td>
                  <td class="data">
                    {{ this.record.mobile  }}
                  </td>
                  <td class="first"></td>
                  <td class="data">
                  </td>
                </tr>
                <tr>
                  <td class="first">下单时间：</td>
                  <td class="data">
                    {{ this.record.createTime  }}
                  </td>
                  <td class="first">支付时间：</td>
                  <td class="data">
                    {{ this.record.payTime  }}
                  </td>
                  <td class="first">支付方式：</td>
                  <td class="data">
                    {{ this.record.payTypeName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">订单金额：</td>
                  <td class="data">
                    {{ this.record.payAmount  }}
                  </td>
                  <td class="first">微信订单号：</td>
                  <td class="data">
                    {{ this.record.outTradeNo  }}
                  </td>
                  <td class="first"></td>
                  <td class="data">
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="广告信息"
                     :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                     :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">广告名称：</td>
                  <td class="data">
                    {{ this.record.orderAdvertSnapshot.advertName  }}
                  </td>
                  <td class="first">投放套餐：</td>
                  <td class="data">
                    {{ this.record.timePackage  }}
                  </td>
                  <td class="first">投放规格：</td>
                  <td class="data">
                    {{this.record.spec  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">单价：</td>
                  <td class="data">
                    {{ this.record.price  }}
                  </td>
                  <td class="first">投放数量：</td>
                  <td class="data">
                    {{ this.record.count  }}
                  </td>
                  <td class="first"></td>
                  <td class="data">
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>

        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="下单信息"
                     :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                     :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">公司名称：</td>
                  <td class="data">
                    {{ this.record.signName  }}
                  </td>
                  <td class="first">投放品牌：</td>
                  <td class="data">
                    {{ this.record.brandName  }}
                  </td>
                  <td class="first">联系人：</td>
                  <td class="data">
                    {{this.record.contactName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">联系电话：</td>
                  <td class="data">
                    {{ this.record.contactPhone  }}
                  </td>
                  <td class="first">对接人：</td>
                  <td class="data">
                    {{ this.record.dockingName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">投放开始时间：</td>
                  <td class="data">
                    {{ this.record.startTime  }}
                  </td>
                  <td class="first">投放结束时间：</td>
                  <td class="data">
                    {{ this.record.endTime  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">已选日期：</td>
                  <td class="data">
                    <li v-for="item in this.record.advertTimeGroupList">
                      {{ item }}
                    </li>
                  </td>
                </tr>
                <tr>
                  <td class="first">其他需求：</td>
                  <td class="data">
                    {{ this.record.remark  }}
                  </td>
                  <td class="first">后台备注：</td>
                  <td class="data">
                    {{ this.record.adminRemark  }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
      </table>
    </div>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary"  v-hasPermi="['order:advert:pay']"  v-if="this.record.orderStatus === 4010" :loading="submitLoading" @click="handlePay">
          确认支付
        </a-button>
        <a-button type="primary"  v-hasPermi="['order:advert:refund']"  v-if="this.record.isCanRefund" :loading="submitLoading" @click="handleRefund">
          确认退款
        </a-button>
        <a-button type="dashed" @click="cancel">
          关闭
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import {getAdvert, payAdvert, refundAdvert} from '@/api/order/advert'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'detailForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      record:null ,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        outTradeNo: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        outTradeNo: null,
        remark: null,
      }
    },
    /** 修改按钮操作 */
    handleDetail(id) {
      this.reset()
      this.formType = 2
      getAdvert({"id":id}).then(response => {
        this.record = response.data
        this.open = true
        this.formTitle = '订单详情'
      })
    },
    handlePay() {
      var id = this.record.id
      payAdvert({"id":id}).then(response => {
        this.$message.success(
          '确认支付成功',
          3
        )
      }).finally(() => {
        this.submitLoading = false
      })
    },


    handleRefund() {
      var id = this.record.id
      refundAdvert({"id":id}).then(response => {
        this.$message.success(
          '退款成功:'+response.data,
          3
        )
        this.handleDetail(id)
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            payAdvert(this.form).then(response => {
              this.$message.success(
                '支付成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}


.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    tr {
      height: 50px;
    }
    td {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
    }

    .first {
      width: 100px;
    }

    .data {
      width: 300px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>
