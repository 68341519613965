import request from '@/utils/request'


// 查询订单-广告订单列表
export function listAdvert(query) {
  return request({
    url: '/order/advert/list',
    method: 'get',
    params: query
  })
}

// 查询订单-广告订单分页
export function pageAdvert(query) {
  return request({
    url: '/order/advert/page',
    method: 'get',
    params: query
  })
}

// 查询订单-广告订单详细
export function getAdvert(data) {
  return request({
    url: '/order/advert/detail',
    method: 'get',
    params: data
  })
}


// 新增订单-广告订单
export function addAdvert(data) {
  return request({
    url: '/order/advert/add',
    method: 'post',
    data: data
  })
}

export function refundAdvert(data) {
  return request({
    url: '/order/advert/refund',
    method: 'post',
    data: data
  })
}

// 修改订单-广告订单
export function updateAdvert(data) {
  return request({
    url: '/order/advert/edit',
    method: 'post',
    data: data
  })
}
export function auditAdvert(data) {
  return request({
    url: '/order/advert/audit',
    method: 'post',
    data: data
  })
}
export function payAdvert(data) {
  return request({
    url: '/order/advert/pay',
    method: 'post',
    data: data
  })
}
export function cancelAdvert(data) {
  return request({
    url: '/order/advert/cancel',
    method: 'post',
    data: data
  })
}
// 删除订单-广告订单
export function delAdvert(data) {
  return request({
    url: '/order/advert/delete',
    method: 'post',
    data: data
  })
}
